<template>
  <LiefengContent  @backClick="clickBar" isBack="true" :navList="navList" @clickBar="clickBar">
      <template #title>{{titleName}}</template>
      <template #toolsbarRight v-if="navList.length != 4 ">
        <Select  
            :label-in-value='true'
            v-model="selectValue" 
            style="width:200px" 
            @on-change="changeSelect"
            >
            <Option 
                v-for="(item,index) in selectList" 
                :value="item.value" 
                :key="index"
                >{{ item.label }}
            </Option>
        </Select>
      </template>
      <template #contentArea>

          <Card class="card-margin">
              <div class="card-view">
                 <CardItem style="width:600px" cardTitle="用户使用情况" :list="columnList"></CardItem>
             </div>
              <div  class="table-view" style="margin-top:30px">
                  <VeTable :tableData="columnTData" :tableColumn="columnTColumn"></VeTable>
              </div>
          </Card>

          <Card class="card-margin">
              <TitleColor  :title="navList.length == 2? '各街道运营数据统计':navList.length==3?'各社区运营数据统计':'各服务主体营数据统计'">
                </TitleColor>
                <div  class="table-view">
                    <VeTable :tableData="operationData" :tableColumn="operationColumn"></VeTable>
                </div>
                <div  class="bar-div line">
                    <VeBar :showSelect="false"  :data="columnBarData"  ></VeBar>
                </div>
          </Card>

          <Card class="card-margin">
              <div class="ranking">
                    <div class="visit ">
                        <TitleColor  title='用户访问排行'></TitleColor>
                        <Table class="table-div" border  height="500" :data="visitData" :columns="visitColumn"></Table>
                    </div>

                    <div class="partake">
                        <TitleColor  title='用户参与排行'></TitleColor>
                        <Table class="table-div" border  height="500" :data="partakeData" :columns="partakeColumn"></Table>
                    </div>
                </div> 
          </Card>

          <Card class="card-margin">
              <TitleColor title='用户关注度分析'></TitleColor>
                <div class="user-view">
                    <TableList class="list-div" titleName="最新热门资讯" :tableList='infomationList'></TableList>
                    <TableList class="list-div" titleName="最新热门活动" :tableList='infomationTwoList'></TableList>
                </div> 
          </Card>
      </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from '../components/LiefengContent2.vue'
import TitleColor from '../components/TitleColor'
import VeRing from '../components/VeRing'
import VeTable from '../components/Table'
import VeBar from '../components/VeBar'
import CardItem from '../components/CardItem'
import VeLine from '../components/VeLine'
import TableList from '../components/TableList'
import {mixinRequest} from '../utils/mixinRequest'
export default {
    components:{LiefengContent,TitleColor,VeRing,VeTable,VeBar,CardItem,VeLine,TableList},
    mixins:[mixinRequest],
    data(){
        return{
            // 面包屑导航栏
            navList:[],
            // 当前页面标题
            titleName:'栏目使用情况',
            // 存放用户信息
            portalInfo:{},

            // 绑定的搜索参数
            selectValue:'',
            // 绑定的选择框列表
            selectList:[],
            

            // 顶部栏目数量
            columnList:[
                {title:'点击次数',num:0},
                {title:'点击人数',num:0},
                {title:'参与次数',num:0},
                {title:'参与人数',num:0}
            ],

            // 栏目统计
            columnTColumn:[
                {title:'栏目名称',key:'topColumnName',align:'center'},
                {title:'子栏目名称',key:'columnNameFull',align:'center'},
                {title:'点击量（次）',key:'browseUv',align:'center'},
                {title:'点击量（人）',key:'browsePv',align:'center'},
                {title:'参与数（次）',key:'participateUv',align:'center'},
                {title:'参与数（人）',key:'participatePv',align:'center'},
            ],
            columnTData:[
            ],

            // 运营统数据计
                // 表
            operationColumn:[
                {
                    title:'街道',
                    key:'orgName',
                    align:'center',
                    render:(h,params) => {
                        return h('div',[
                        h('a',{
                            style:{
                              color:this.navList.length == 4? 'black':'#2d8cf0',
                              cursor:this.navList.length == 4? 'auto':'pointer' 
                            },
                            on:{
                                'click':()=>{
                                    // 点击跳转
                                    if(this.navList.length != 4){
                                        let obj = {
                                            label:params.row.orgName,
                                            value:params.row.orgCode
                                        }
                                        this.changeSelect(obj)
                                    }
                                    
                                }
                            }
                        },params.row.orgName)
                        ])
                    }
                    
                },
                {title:'点击量（次）',key:'browseUv',align:'center'},
                {title:'点击量（人）',key:'browsePv',align:'center'},
                {title:'参与数（次）',key:'participateUv',align:'center'},
                {title:'参与数（人）',key:'participatePv',align:'center'},
            ],
            operationData:[
            ],
                // 图
            columnBarData:{
                columns: ["街道", "点击量（次）",'点击量（人）',"参与数（次）",'参与数（人）'],
                rows: []
            },

            // 用户访问排行
            visitColumn:[
                {title:'排名',key:'no',align:'center'},
                {title:'次数',key:'statValue',align:'center'},
                {title:'用户姓名',key:'username',align:'center'},
                {title:'手机',key:'mobile',align:'center'},
            //     {title:'最新一次访问',key:'lastLoginTime',align:'center',
            //     render:(h,params) =>{
            //     return h('div',{},params.row.lastLoginTime ? this.$core.formatDate(
            //       new Date(params.row.lastLoginTime),
            //         "yyyy-MM-dd"
            //       ):'')
            //   }},
            ],
            visitData:[
            ],

            // 用户参与排名
            partakeColumn:[
                {title:'排名',key:'no',align:'center'},
                {title:'次数',key:'statValue',align:'center'},
                {title:'用户姓名',key:'username',align:'center'},
                {title:'手机',key:'mobile',align:'center'},
            ],
            partakeData:[
            ],

            // 用户关注度分析
                // 咨询
            infomationList:[
            ],
            infomationTwoList:[
            ],
        }
    },
    async created(){
        this.portalInfo = this.$store.state.portal ? this.$store.state.portal : {}
        if(this.portalInfo){
            if(this.portalInfo.orgLevel == 3){
                // 如果当前是区，进来是街道
                this.titleName = this.portalInfo.orgName + '栏目使用情况'
                this.selectValue = ''
                this.selectList =  this.formatterData(await this.getSelectList(this.portalInfo.orgCode))
            }
        }
        this.navList.push(...(JSON.parse(this.$route.query.router)),{
            name:this.titleName,
            router:this.$route.path,
            orgCode:this.portalInfo.orgCode
        })
        await this.getData(this.portalInfo.orgCode)
        console.log(this.selectList)
        
    },
    methods:{
        async changeSelect(val){
            this.searchDate = []
            this.index = 0
            if(this.navList.length == 2){
            console.log(val)
            console.log(this.titleName)
            this.titleName = val.label+"栏目使用情况"
            console.log(this.titleName)
            this.selectList = []
            this.selectList = this.formatterData(await this.getSelectList(val.value))
            this.selectValue = ''
            this.navList.push({
                name:this.titleName,
                router:this.$route.path,
                orgCode:val.value
            })
              this.getData(val.value)
            }else if(this.navList.length == 3){

            this.titleName = val.label+"栏目使用情况"
            this.selectValue = ''
            this.navList.push({
                name:this.titleName,
                router:this.$route.path,
                orgCode:val.value
            })
              this.getData(val.value)
            }
            console.log(this.selectList)
        },
        async clickBar(item,index){
            if(!item){
            // 点击返回
            this.navList.splice(this.navList.length - 1,1)
            this.index = 0
            this.titleName = this.navList[this.navList.length-1].name
            this.selectValue = ''
            this.selectList =  this.formatterData(await this.getSelectList(this.navList[this.navList.length-1].orgCode))
            await this.getData(this.navList[this.navList.length-1].orgCode)
            }else{
            // 点击面包屑
            this.navList.splice(index + 1,99)
            this.index = 0
            this.titleName = item.name
            this.selectValue = ''
            this.selectList =  this.formatterData(await this.getSelectList(item.orgCode))
            await this.getData(item.orgCode)
            }
        },
        async getData(orgCode){
            if (this.portalInfo && this.portalInfo.orgLevel != "") {

                //头部用户数
                let data1 = await this.getSelectColumnUseData(orgCode)
                if(data1)
                this.columnList = [
                    {title:'点击次数',num:data1.browseUv || 0},
                    {title:'点击人数',num:data1.browsePv || 0},
                    {title:'参与次数',num:data1.participateUv || 0},
                    {title:'参与人数',num:data1.participatePv || 0}
                ]

                // 栏目统计
                
                
                let data2 = await this.selectColumnUseDataInfo(orgCode)
                this.columnTData = data2

                //下级运营数据
                this.operationColumn[0].title = this.navList.length==2?'街道':this.navList.length==3?'社区':'服务主体'
                this.columnBarData.rows = []
                let data3 = await this.selectColumnUseChildData(orgCode)
                this.operationData = data3
                data3.map( e=> {
                    this.columnBarData.rows.push( 
                        { 街道: e.orgName, "点击量（次）":e.browseUv,"点击量（人）":e.browsePv,"参与数（次）":e.participateUv,"参与数（人）":e.participatePv },
                    )
                })
                
            

                let parmas = {
                    orgCode,
                    statType:'1',
                }
                let parmasTwo = {
                    orgCode,
                    statType:'2',
                }

                // 用户访问排行
                this.visitData = await this.selectUserBehavior(parmas)
                // 用户参与排行
                this.partakeData = await this.selectUserBehavior(parmasTwo)

                // 用户关注度分析
                this.infomationList = []
                this.infomationTwoList = []
                let data = await this.selectInformationBehavior(parmas)
                data.map(item =>{
                    this.infomationList.push({
                        title:item.title,
                        num:`点击${item.statValue}次`
                    })
                })
                
                let dataTwo = await this.selectInformationBehavior(parmasTwo)
                dataTwo.map(item =>{
                    this.infomationTwoList.push({
                        title:item.title,
                        num:`参与${item.statValue}人`
                    })
                })
            }
        },
        

    
       

       getThirdMonth(){
        //获取当前日期
        var myDate = new Date();
        var nowY = myDate.getFullYear();
        var nowM = myDate.getMonth()+1;
        var nowD = myDate.getDate();
        var enddate = nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD);//当前日期
        //获取三十天前日期
        var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);//最后一个数字30可改，30天的意思
        var lastY = lw.getFullYear();
        var lastM = lw.getMonth()+1;
        var lastD = lw.getDate();
        var startdate=lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD);//三十天之前日期
        return {startDate:startdate,endDate:enddate}
       },
        // 格式化频率数据
       formatterPinlv(data){
           this.lineUsage.lineData = {
                       columns:['日期','数量'],
                       rows:[]
           }
           if(data){
               data.map(item =>{
                   this.lineUsage.lineData.rows.push({
                       '日期':item.statDate || '',
                       '数量':item.createNum || 0
                   })
               })
           }
       }
    }
}
</script>

<style lang="less" scoped>
.card-view{
    display: flex;
    height: 120px;
    justify-content: center;
}
.bar-div{
    width: 100%;
    height: 100%;
    margin-top: 20px;
    // border-top: 1px dashed #ccc;
    padding-top: 10px;
    // &:nth-last-child(1){
    //     border-bottom: none;   
    // }
}
.table-view{
    width: 100%;
    height: 200px;
    padding-bottom: 20px;
}
.select-div{
    height: 30px;
    display: flex;
    .item{
        width: 100px;
        height: 30px;
        line-height:30px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        &:nth-child(2){
            border-left: none
        }
        &-active{
            background:#2d8cf0 ;
            color: #ffffff;
        }
    }
}
.ranking{
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .visit{
        width: 45%;
    } 
    .partake{
        width: 45%;
        //  padding-left: 50px;
    }
    
}
.user-view{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 500px;
    .list-div{
        height: 100%;
        width: 45%;
    }
}
.card-margin {
  margin-top: 30px;
}
</style>